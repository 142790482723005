import React from 'react';
import { Link, FormattedMessage, navigate } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { GridContainer } from '@jackywxd/shared-material-ui';
import { Parallax } from '@jackywxd/shared-material-ui';
import indexStyles from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';
import BlogItem from './BlogItem';
import HelpItem from './HelpItem';

const useStyles = makeStyles(indexStyles);

const Tag = ({ pathContext }) => {
  const classes = useStyles();
  const { section, posts, tagName } = pathContext;
  const totalCount = posts ? posts.length : 0;
  const subline = `${totalCount} post${
    totalCount === 1 ? '' : 's'
  } tagged with "${tagName}"`;

  return (
    <Layout>
      <Parallax small filter style={{ background: 'rgba(66, 133, 243, 0.6)' }}>
        <div className={classes.container}>
          <GridContainer justify="center" alignItems="center">
            <h1 className={classes.title}>
              {section.toUpperCase()} - Taged &ndash; {tagName}
            </h1>
          </GridContainer>
          {subline} (See <Link to={`/${section}/tags`}>all tags</Link>)
        </div>
      </Parallax>
      <main className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {section === 'blog' ? (
            <>
              {posts
                ? posts.map((node: any) => {
                    return (
                      <BlogItem
                        key={node.fields.slug}
                        to={`/blog/${node.fields.slug}`}
                        title={node.frontmatter.title}
                        description={node.frontmatter.description || ''}
                        date={node.frontmatter.date}
                        banner={node.frontmatter.banner}
                        category={node.frontmatter.category}
                        timeToRead={node.timeToRead}
                      />
                    );
                  })
                : null}
            </>
          ) : (
            <>
              {posts
                ? posts.map((node: any) => {
                    return (
                      <HelpItem
                        key={node.fields.slug}
                        to={`/help/${node.fields.slug}`}
                        title={node.frontmatter.title}
                        description={node.frontmatter.description || ''}
                        date={node.frontmatter.date}
                        category={node.frontmatter.category}
                        timeToRead={node.timeToRead}
                      />
                    );
                  })
                : null}
            </>
          )}
        </div>
      </main>
    </Layout>
  );
};

export default Tag;
